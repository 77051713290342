<template>
  <v-sheet v-if="isWeb">
    <div class="footerupper" style='color:#999999;;'>
      <v-row>
        <v-col cols="8">
          <br>
          <p style="color:#999999;font-size:0.7em;margin-left: 10px;">&nbsp;{{ $t('footer.infotxt') }}<br>
            &nbsp;{{ $t('footer.email') }}<br>
          </p>
        </v-col>
        <v-col>
          <p style="font-size:1.3em;margin-bottom: -1px;margin-top: 10px;margin-right: 15px;" align="right">
        <!--
            {{ $t('footer.number') }}
        -->
          </p>
          <p align="right" style="color:#999999;font-size:0.7em;margin-right: 15px;">
        <!--
            {{ $t('footer.availabletime') }}
        -->
          </p>
          <v-row justify="end">
            <!--
            <v-btn text style="color:#999999;font-size:0.7em;margin-top:3px;margin-right: 15px;" @click="getToken()">{{$t('admin.notice.notice')}}</v-btn>
            -->
            <v-btn text style="color:#999999;font-size:0.7em;margin-top:3px;margin-right: 15px;" @click="eura_text_dialog = true">{{
              $t('footer.eula') }}</v-btn>
            <v-btn text style="color:#999999;font-size:0.7em;margin-top:3px;margin-right: 15px;" @click="privacypolicy_text_dialog = true">{{
              $t('footer.privacypolicy') }}</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="footerlower" dark height="60px">
      <p class="footer footer-t--text">
        Copyright © Cardiac Insight Co., Ltd. All rights reserved.
      </p>
    </div>
    <v-dialog v-model="eura_text_dialog" width="700px">
      <v-card>
        <v-card-title class="text-h5 text-center">
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text v-if="$i18n.locale == 'ko'">
          <eura_text />
        </v-card-text>
        <v-card-text v-if="$i18n.locale == 'en'">
          <eura_text_en />
        </v-card-text>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-dialog v-model="privacypolicy_text_dialog" width="700px">
      <v-card>
        <v-card-title class="text-h5 text-center">
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text v-if="$i18n.locale == 'ko'">
          <privacypolicy_text />
        </v-card-text>
        <v-card-text v-if="$i18n.locale == 'en'">
          <privacypolicy_text_en />
        </v-card-text>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
    <v-dialog v-model="notice_dialog" max-width="700">
      <notice_list />
    </v-dialog>
    <v-dialog v-model="nologin_notice_dialog" max-width="700">
      <v-card max-width="700">
        <v-card-title>{{ $t('admin.notice.notice') }}</v-card-title>
        <v-card-text>
          {{ $t('footer.afterlogin') }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-sheet>
  <v-sheet v-else>
    <div class="footerlower" dark height="60px">
      <p class="footer footer-t--text">
        Copyright © Cardiac Insight Co., Ltd. All rights reserved.
      </p>
    </div>
  </v-sheet>
</template>

<script>
import eura_text from './EURA'
import eura_text_en from './EURA_en'
import privacypolicy_text from './PrivacyPolicy'
import privacypolicy_text_en from './PrivacyPolicy_en'
import notice_list from './NoticeList'

export default {
  name: 'ComponentTemplate',
  components: {
    eura_text,
    eura_text_en,
    privacypolicy_text,
    privacypolicy_text_en,
    notice_list,
  },
  created() {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    this.isWeb = mediaQuery.matches;
    mediaQuery.addListener((event) => {
      this.isWeb = event.matches;
    });
  },
  data() {
    return {
      eura_text_dialog: false,
      privacypolicy_text_dialog: false,
      notice_dialog: false,
      nologin_notice_dialog: false,
      isToken: '',
      isWeb: false,
    }
  },
  methods: {
    getToken() {
      this.isToken = this.$session.get("token")
      if (this.isToken != undefined) {
        this.notice_dialog = true;
      }
      else {
        this.nologin_notice_dialog = true
      }
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
<style>
.footerupper {
  height: 100px;
  background-color: #FFFFFF;
}
.footerlower {
  height: 50px;
  text-align: center;
  padding: 12px;
  background-color: #FFFFFF;
}
</style>

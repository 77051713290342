import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login'),
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home'),
      props: true,
      children: [
        {
          path: '/',
          name: 'home',
          props: true,
          component: () => import('@/views/patch/List'),
        },
        {
          path: '/upload',
          name: 'upload',
          props: true,
          component: () => import('@/views/patch/RequestUpload'),
        },
        {
          path: '/detail',
          name: 'detail',
          props: true,
          component: () => import('@/views/patch/Detail'),
        },
      ]
    },
    {
      path: '/agreement/:case_id',
      name: 'agreement',
      component: () => import('@/views/mobile/Agreement'),
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/admin/AdminHome'),
      children:[
        {
          path: '/admin',
          name: 'Dashboard',
          props:true,
          //component: () => import('@/views/admin/DashBoard'),
          component: () => import('@/views/admin/User'),
        },
        {
          path: '/statistics',
          name: 'Statistics',
          props:true,
          component: () => import('@/views/admin/Statistics'),
        },
        {
          path: '/user',
          name: 'user',
          props:true,
          component: () => import('@/views/admin/User'),
        },
        {
          path: '/userdetailadmin',
          name: 'userdetailadmin',
          props:true,
          component: () => import('@/views/admin/UserDetailAdmin'),
        },
       
        {
          path: '/patchdetail',
          name: 'patchdetail',
          props:true,
          component: () => import('@/views/admin/PatchDetail'),
        },
        {
          path: '/patchlist',
          name: 'patchlist',
          props:true,
          component: () => import('@/views/admin/PatchList'),
        },
        {
          path: '/facility',
          name: 'facility',
          props:true,
          component: () => import('@/views/admin/Facility'),
        },
        {
          path: '/facilitydetail',
          name: 'facilitydetail',
          props:true,
          component: () => import('@/views/admin/FacilityDetail'),
        },
        {
          path: '/billing',
          name: 'billing',
          props:true,
          component: () => import('@/views/admin/Billing'),
        },
        {
          path: '/billingdetail',
          name: 'billingdetail',
          props:true,
          component: () => import('@/views/admin/BillingDetail'),
        },
        {
          path: '/notice',
          name: 'notice',
          props:true,
          component: () => import('@/views/admin/Notice'),
        },
        {
          path: '/noticedetail',
          name: 'noticedetail',
          props:true,
          component: () => import('@/views/admin/NoticeDetail'),
        },
      ]
    },
    {
      path: "/notFound",
      name: "notFound",
      component: () => import('@/views/NotFound'),
    },
    {
      path: "*",
      component: () => import('@/views/NotFound'),
    },
  ]
})

export default router
